@import "~Styles/colors.module";
@import "~Styles/mixins.module";

.project_image {
  position: absolute;
  object-fit: contain;
}

.project_name {
  position: absolute;
  font-size: 18px;
  color: white;
  line-height: 1;
  white-space: nowrap;
}

.project_sub_details_container {
  position: absolute;
  right: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;

  .title {
    color: $color-brown-grey;
    margin-bottom: 2px;
  }

  .value {
    color: white;
    text-transform: capitalize;
  }

  .project_involvement {
    margin-top: 15px;
  }
}

@include small {
  .project_sub_details_container {
    display: none;
  }
}
