@import '~Styles/colors.module';
@import '~Styles/mixins.module';

.content {
  // max-width: 1024px;
  display: grid;
  grid-gap: 20px;
  padding: 0px 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 300px;

  .image_container {
    position: relative;

    .image {
      width: 100%;
      height: 100%;
      object-position: top;
      object-fit: cover;  
    }

    box-shadow: 0px 5px 12px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    
    cursor: pointer;
    transition: transform 500ms cubic-bezier(0.1, 0.82, 0.25, 1);
  
    &:hover {
      transform: scale(1.02);

      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: 'View Image';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      border-radius: 12px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;

      background: rgba($color-dark, 0.7);
      transition: opacity 0.6s ease;
    }
  }
}

.grid_column_3 {
  grid-column: span 3;
}
.grid_column_2 {
  grid-column: span 2;
}
.grid_column_1 {
  grid-column: span 1;
}

.grid_row_3 {
  grid-row: span 3;
}
.grid_row_2 {
  grid-row: span 2;
}
.grid_row_1 {
  grid-row: span 1;
}


@include large {
  .content {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .grid_column_3 {
    grid-column: span 2;
  }

  .grid_column_2 {
    grid-column: span 2;
  }

  .grid_column_1 {
    grid-column: span 2;
  }



  .grid_row_3 {
    grid-row: span 1;    
  }

  .grid_row_2 {
    grid-row: span 1;    
  }

  .grid_row_1 {
    grid-row: span 1;    
  }
}
